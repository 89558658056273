import React, {useState} from 'react';
import {LoaderComponent} from 'ht-styleguide';
import cn from 'classnames';

import styles from './gladly.module.scss';

/**
 * Trying to get around the Gladly Chat - it's very detrimental to the performance of the site.
 * So, we are going to lazy load it with 8 seconds delay, BUT offer the visual cue to the user that they can
 * click it and initiate the load sooner.
 *
 * @param {BaseAnyFunction} loadGladlyScript
 * @returns {React.JSX.Element}
 * @constructor
 */
const GladlyPlaceholder = ({loadGladlyScript, displayEmptyButton}: {loadGladlyScript?: BaseAnyFunction; displayEmptyButton?: boolean}) => {
  const [isLoading, setIsLoading] = useState(false);
  const classPlaceholder = cn(styles.gladlyPlaceholder, {
    [styles.isLoading]: isLoading,
  });

  return (
    <>
      <div
        tabIndex={0}
        role="button"
        className={cn(classPlaceholder, 'iconWrapper')}
        id="gladlyPlaceholderImage"
        onClick={() => {
          if ('Gladly' in window && 'show' in window?.Gladly) {
            window?.Gladly?.show?.();
          } else {
            setIsLoading(true);
            loadGladlyScript?.({setOpen: true});
          }
        }}
      >
        <img src="/_nextassets_/icons/gladly_chat_button.svg" alt="Need assistance?" />
        {isLoading && (
          <div className={styles.chatLoader}>
            <LoaderComponent />
          </div>
        )}
      </div>
    </>
  );
};

export default GladlyPlaceholder;
